/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import CollectionDetail from "../components/collection-detail"
import SEO from "../components/seo"
import { useHistory } from "../hooks/use-history"

const EventTemplate = ({ data }) => {
  const collection = data.collection
  useHistory("event-history", "c/" + collection.contentful_id)
  return (
    <React.Fragment>
      {collection && (
        <SEO
          title={collection.name}
          description={
            collection.description && collection.description.description
          }
          imageUrl={collection.image && collection.image.file.url + "?w=512"}
        />
      )}
      {collection && <CollectionDetail collection={collection} />}
    </React.Fragment>
  )
}

export default EventTemplate

export const query = graphql`
  query($id: String!) {
    collection: contentfulCollection(contentful_id: { eq: $id }) {
      contentful_id
      name
      description {
        description
      }
      image {
        file {
          url
        }
      }
      discipline {
        disciplineName
        image {
          file {
            url
          }
        }
      }
      website {
        website
      }
      startDate
      endDate
      organization {
        name
        email
        organizationNumber
        website
        additionalContactInfo {
          additionalContactInfo
        }
        phone
        address
        city
        state
        zip
        logo {
          file {
            url
          }
        }
        description {
          description
        }
        facebook
        instagram
        twitter
        youtube
        approved
        secretSlug
      }
    }
  }
`
